import { House, User, PenLine } from "lucide-svelte";
import Facebook from "./components/icon/Facebook.svelte";
import Instagram from "./components/icon/Instagram.svelte";
import Bluesky from "./components/icon/Bluesky.svelte";

export const siteConfig = {
    title: "Rookie's Blog",
    tagline: "A place where I just write about random stuffs. Of everything and all.",
    baseUrl: "https://itsrookienguyen.me",
    routes: [
        {
            name: "Home",
            path: "/",
            sidebarIcon: House
        },
        {
            name: "About",
            path: "/about",
            sidebarIcon: User
        },
        {
            name: "Writings",
            path: "/writings",
            sidebarIcon: PenLine
        }
    ],
    socials: [
        {
            name: "Facebook",
            link: "https://fb.com/realrookie001",
            icon: Facebook
        },
        {
            name: "Instagram",
            link: "https://www.instagram.com/rookienguyen/",
            icon: Instagram
        },
        {
            name: "Bluesky",
            link: "https://bsky.app/profile/itsrookienguyen.me",
            icon: Bluesky
        }
    ],
    categories: [
        "Nguyen Huu Quoc Thang",
        "Nguyễn Hữu Quốc Thắng",
        "Rookie Nguyen",
        "Rookie Nguyễn",
        "Esports",
        "Livestream",
        "Broadcast",
        "Web Developer",
        "Life"
    ]
}