<script>
	import { siBluesky } from "simple-icons";

	/** @type {{color?: any, size?: number, title?: any}} */
	let { color = siBluesky.hex, size = 24, title = siBluesky.title } = $props();
</script>

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={size} height={size} fill={color}>
	<title>{title}</title>
	<path d={siBluesky.path} />
</svg>
